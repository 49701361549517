.corporate__avatar {
    width:                                        14%;
    margin-right:                                 0px;
}

.corporate__avatar img {
    width:                                        100%;
}

.corporate__avatarRow .d-flex {
    align-items:                                  flex-start;
}
.corporate__detail h2 {
    font-size:                                    1.125rem;
    margin-bottom:                                0.5rem;
}
.corporate__companyName {
    margin-bottom:                                1rem !important;
}
.corporate__detail p,
table {
    font-size:                                    var(--font-size-sm);
}
.corporate__detail p {
    margin-bottom:                                1rem !important;
}
.corporate__detail-left {
    margin-right:                                 48px;
}
.corporate__detail-right th {
    text-align:                                   left;
    color:                                        var(--primary);
    padding:                                      6px 0px 6px 0;
}
.corporate__detail-right tr {
    background-color:                             transparent !important;
}
.corporate__detail-right {
    width:                                        234px;
    margin-right:                                 26px;
}
.corporate__detail-right table {
    width:                                        100%;
}
.corporate__avatarRow {
    background-color:                             #fff;
    padding:                                      24px;
}
p.corporate__detail-company {
    font-size: 0.687rem;
    font-weight: 600;
    color: var(--primary);
}
.ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: 0px !important;
}
.ant-tabs-tab-btn {
    font-size: var(--font-size-md);
    color: #686868;
    font-weight: 700;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--primary) !important;
}
.ant-tabs-ink-bar {
    background: var(--primary) !important;
}
.genic__tabs .ant-tabs-nav-wrap {
    background: #fff;
    padding: 20px 0px 0px 24px;
}
/* .genic__tabs .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 92px;
} */
.profile__tab {
    background-color: #fff;
    padding: 24px;
}

.profile__tab th {
    padding: 14px 0px;
}

.profile__tabOne {
    width: 40%;
    margin-right: 30px;
}
.profile__tabTwo {
    width: 60%;
}
.account-setting {
    background-color: var(--white);
    padding: 24px;
}
.account__form .form-group {
    width: 45%;
}
.account__form .form-group input {
    width: 90%;
}
.account__form {
    width: 90%;
}
.account__moreFields-wrapper .ant-space-item {
    /* width: 20%; */
    margin-right: 23px;
}
.account__moreFields-wrapper .ant-space {
    align-items: center;
}
.circle-minus {
    width: 20px;
    cursor: pointer;
}


/* Media queries */

@media(max-width:552px) {
    .profile__tabOne {
        width: 100%;
        margin-right: 0;
    }

    .profile__tabTwo {
        width: 100%;
        margin-top: 16px;
    }

    .account__form,
    .account__form .form-group,
    .account__form .form-group input,
    .account__moreFields-wrapper .ant-space-item {
        width: 100%;
    }

    .account__moreFields-wrapper .ant-space-item {
        margin-right: 0px; 
    }

    .ant-space.ant-space-horizontal.ant-space-align-baseline {
        flex-direction: column;
    }

    .circle-minus {
        margin-bottom: 20px;
    }
}