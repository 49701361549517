.vendorInvoice__wrapper {
    background-color: #fff;
    padding: 24px;
}
.vendorInvoice__logo {
    padding: 14px 0px 20px 0px;
    border-bottom: 1px solid #bdbec0;
    margin-bottom: 20px;
}
.vendorInvoice__detail h2 {
    font-size: 1.125rem;
    color: #686868;
    margin-bottom: 0.5rem !important;
}
p.vendorInvoice__invo-num {
    color: var(--primary) !important;
}
.vendorInvoice__detail p {
    margin-bottom: 1rem !important;
    font-size: var(--font-size-md);
    color: #000000d9;
}
/* .vendorInvoice-flex {
    width: 33%;
} */
.issuanceAuth {
    color: #686868 !important;
}
.vendorInvoice-flex table th {
    text-align: left;
    color: var(--primary);
    padding-right: 20px;
    padding-bottom: 5px;
    padding-top: 5px;
    font-weight: 400;
}
.overdue {
    color: var(--primary);
}
.vendorInvoice__detail {
    padding: 0px 0px 20px 0px;
    border-bottom: 1px solid #bdbec0;
    margin-bottom: 20px;
}
.approve-text {
    font-size: var(--font-size-md);
    color: red;
}
.reminder {
    font-size: var(--font-size-sm);
    color: #000000d9;
    cursor: pointer;
}
.invoice__tabs .ant-tabs-tab-btn {
    color: #000000d9 !important;
    font-weight: 400 !important;
    background-color: transparent;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--white) !important;
    background-color: var(--primary);
    padding: 5px 10px;
    border-radius: 4px;
    font-weight: 400;
}
.ant-tabs-ink-bar {
    display: none;
}
.inv-table .table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
    background-color: #fcfcfc !important;
}
.inv-table th {
    color: #605bff !important;
    font-weight: 400 !important;
}
.table-bordered,
.table-bordered th,
.table-bordered td {
    border-bottom: 1px solid #dee2e6;
}
.inv-table .table th,
.inv-table .table td {
    padding: 1rem 0.5rem !important;
}
.inv-table table th,
.inv-table table td,
.invoices-tab-table th,
.invoices-tab-table td {
    padding-left: 30px !important;
}
.invoice__tabs .ant-tabs-nav-wrap {
    justify-content: end;
}
.inv-total td {
    font-weight: 700;
}
.inv-table thead > tr {
    background-color: #f6f6f6;
}
.invo__title {
    position: relative;
}
.invo__title .dashboard-title {
    /* position: absolute; */
    top: 20px;
}
.approve-div {
    margin-bottom: 24px;
}
