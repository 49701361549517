.login-bg {
  height:               0;
  /* background-image: url('/assets/images/login-bg.jpg'); */
  background-repeat:    no-repeat;
  background-size:      cover;
}

@media (min-width: 992px) {
  .login-bg {
    height:               100vh;
  }
}

.login-form {
  width:                360px;
  border-radius:        25px;
}

.forgot-link {
  text-decoration:      underline;
}

.title {
  font-size:            1 * 1.375rem;
}

.title::after {
  content:              "";
  display:              block;
  width:                30px;
  height:               4px;
  background-color:     #9FD7F5;
  margin-left:          3%;
  margin-top:           5px;
  border-radius:        15px;
}

.lock-icon {
  position:             absolute;
  top:                  30px;
  right:                4px;
  font-size:            14px;
}



.was-validated .form-control:invalid, .form-control.is-invalid {
  background:                 none !important;
  border-color:               none !important;
  border-bottom-color:        var(--primary) !important
}
.was-validated .form-control:valid, .form-control.is-valid {
  background:                 none !important;
  border-color:               none !important;
  border-bottom-color:        var(--primary) !important
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus,
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus,
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus,
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  box-shadow:                 none;
}
.errors .invalid-feedback {
  display:                    block !important;
}
.was-validated .custom-select:invalid, .custom-select.is-invalid {
  background-image:           none;
  border-bottom-color:        var(--primary) !important
}
.was-validated .custom-select:valid, .custom-select.is-valid {
  background-image:           none;
  border-bottom-color:        var(--primary) !important
}

.invalid-feedback {
  color: var(--primary) !important;
}