/* .paymentDetail__Two {
    width: 15% !important;
} */
.issueDate {
    color: var(--primary) !important;
}
.issueDate span {
    color: #0000009b;
    margin-left: 16px;
}
.comment-history-content {
    background: #f3f3f5;
    padding: 24px;
    border-radius: 12px;
    /* flex-basis: 30%; */
    margin-right: 5px;
    margin-top: 30px;
    margin-bottom: 10px;
}
.comment-history-role {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.comment-history-text p {
    color: #605bff;
    text-align: center;
    border: 1px solid #2f77b2;
    border-radius: 23px;
    padding: 6px 16px;
    background: #fff;
    margin-bottom: 1rem !important;
}
.payment-status p {
    color: #707070;
}


@media(max-width:552px) {
	.comment-history-content {
		margin-right: 0px;
	}
}

@media(max-width:768px) {
	.comment-history-role {
		display: block;
	}
}

@media(min-width:768px) {
	.comment-history-content {
		width: 300px;
	} 
}